<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="searchName"
            append-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="country"
            :items="countries_list"
            :loading="isLoadingCountry"
            :search-input.sync="searchCountry"
            @focus="() => onLoadCountries()"
            item-text="name"
            item-value="id"
            label="Country"
            placeholder="Search"
            clearable
            return-object
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            v-model="user"
            :items="users_list"
            :loading="isLoadingUser"
            :search-input.sync="searchUser"
            @focus="() => onLoadUsers()"
            item-text="display_name"
            label="User"
            placeholder="Search"
            clearable
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="list"
        hide-default-footer
        :loading="loading"
        :itemsPerPage="+pageFilter.limit"
        disable-sort
      >
        <template v-slot:header.name="{ header }">
          {{ header.text }}
        </template>

        <template v-slot:item.total_links="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.total_links }}</span>
            </template>
            <span>Total links</span> </v-tooltip
          >\<v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ item.actual_links || 0 }}</span>
            </template>
            <span>Actual links</span>
          </v-tooltip>
        </template>

        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>

        <template v-slot:item.country="{ item }">
          <country-flag
            v-if="item.country"
            :country="item.country.code"
            size="small"
            :title="item.country.name"
          />
        </template>

        <template v-slot:item.broadcast_target="{ item }">
          {{ getTargetName(item.broadcast_target) }}
        </template>

        <template v-slot:item.total_clicks="{ item }">
          <span>{{ item.clicks || 0 }}</span>
        </template>

        <template v-slot:item.name="{ item }">
          <div>
            <span>
              {{ item.name }}
            </span>

            <v-chip
              v-for="tag in item.tags"
              :key="tag"
              small
              color="info"
              class="mr-1"
            >
              {{ tag }}
            </v-chip>
          </div>

          <!--          <v-btn text @click="toggleOpenDetailsModal(item.id)">-->
          <!--            {{item.name}}-->
          <!--          </v-btn>-->
        </template>

        <template v-slot:item.user="{ item }">
          <span class="text-capitalize">{{ item.user.username }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div>
            <v-btn
              icon
              :color="getColorBroadcastStatus(item.status)"
              :title="`Retry ${item.status_comment || ''}`"
              class="mr-1"
              @click="retry(item.id)"
              :disabled="isLoadingAction('retry', item.id)"
            >
              <v-icon>mdi-autorenew</v-icon>
            </v-btn>
            <v-btn
              icon
              :color="getColorBroadcastStatus(item.file_status)"
              :title="`Recreate File ${item.file_status_comment || ''}`"
              class="mr-1"
              @click="createFile(item.id)"
              :disabled="isLoadingAction('create_file', item.id)"
            >
              <v-icon>mdi-file-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              :color="getColorBroadcastStatus(item.shortify_status)"
              :title="`Fill Shortify ${item.shortify_status_comment || ''}`"
              class="mr-1"
              @click="fillCache(item.id)"
              :disabled="isLoadingAction('fill_cache', item.id)"
            >
              <v-icon>mdi-link-variant</v-icon>
            </v-btn>
            <v-btn
              icon
              color="primary"
              :href="item.csv"
              :title="`CSV download`"
              :disabled="!item.csv"
            >
              <v-icon>mdi-file-download-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              color="success"
              :title="`Edit`"
              @click="openEditDialog(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:footer>
          <pagination :count="count" />
        </template>
      </v-data-table>
    </v-card-text>
    <EditBroadcastDialog />
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";
import CountryFlag from "vue-country-flag";
import { LOAD_LIST } from "../../../store/modules/contacts/types";
import { onLoadUserInfo } from "../../../store/modules/broadcasts/api";
import {
  renderDateTime,
  requestStatus,
  buildFilterString,
  getColorBroadcastStatus,
  BROADCAST_TARGETS,
} from "@/services/services";
import Pagination from "@/components/Pagination";
import * as BROADCASTS_TYPES from "../../../store/modules/broadcasts/types";
import { CHANGE_FILTER } from "../../../store/modules/page/types";
import EditBroadcastDialog from "../components/EditBroadcastDialog.vue";
export default {
  name: "BroadcastsTable",
  components: { Pagination, CountryFlag, EditBroadcastDialog },
  props: { toggleOpenDetailsModal: Function },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "User", value: "user" },
      { text: "Country", value: "country" },
      { text: "Target", value: "broadcast_target" },
      { text: "Links", value: "total_links" },
      { text: "Leads", value: "leads" },
      { text: "Revenue", value: "revenue", formatter: (x) => "$" + (x || 0) },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
      {
        text: "Clicks",
        value: "total_clicks",
      },
      {
        text: "CTR",
        value: "click_rate",
        formatter: (x) => (x || 0) + "%",
      },
      { text: "Actions", value: "actions" },
    ],
    searchName: "",
    loading: false,
    country: null,
    user: null,
    searchCountry: "",
    searchUser: "",
    isLoadingCountry: false,
    isLoadingUser: false,
    temp: {},
  }),
  computed: {
    ...mapState({
      list: (state) => state.broadcasts.list,
      count: (state) => state.broadcasts.count,
      status: (state) => state.broadcasts.status,
      pageFilter: (state) => state.page.filter,
      pageFilterCountry: (state) => +state.page.filter.country || null,
      pageFilterUser: (state) => +state.page.filter.user || null,
      countries: (state) => state.broadcasts.countries,
      users: (state) => state.broadcasts.users,
      countryDetails: (state) => state.broadcasts.countryDetails,
    }),
    getColorBroadcastStatus: () => getColorBroadcastStatus,
    ROADCAST_TARGETS: () => BROADCAST_TARGETS,
    countries_list: function () {
      let results = [...this.countries];
      if (this.country) {
        let default_item = results.filter(
          (item) => +this.country.id === +item.id
        );
        if (!default_item.length) {
          results.push(this.country);
        }
      }
      return results;
    },
    users_list: function () {
      let results = [...this.users];
      if (this.user) {
        let default_item = results.filter((item) => +this.user.id === +item.id);
        if (!default_item.length) {
          results.push(this.user);
        }
      }
      return results;
    },
  },
  mounted() {
    window.getApp.$on("UPDATE_BROADCAST_LIST", () => {
      this.onLoadData(this.pageFilter);
    });
  },
  destroyed() {
    window.getApp.$off("UPDATE_BROADCAST_LIST");
  },
  methods: {
    ...mapActions({
      onLoadList: `broadcasts/${LOAD_LIST}`,
      onLoadCountries: `broadcasts/${BROADCASTS_TYPES.LOAD_COUNTRIES}`,
      onLoadUsers: `broadcasts/${BROADCASTS_TYPES.LOAD_USERS}`,
      onLoadCountryDetails: `broadcasts/${BROADCASTS_TYPES.LOAD_COUNTRY_DETAILS}`,
      fillCache: `broadcasts/${BROADCASTS_TYPES.FILL_CACHE}`,
      createFile: `broadcasts/${BROADCASTS_TYPES.CREATE_FILE}`,
      retry: `broadcasts/${BROADCASTS_TYPES.RETRY}`,
    }),
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    openEditDialog(item) {
      window.getApp.$emit("OPEN_BROADCAST_UPDATE_DIALOG", item);
    },
    onLoadData(pageFilter) {
      this.onLoadList(
        `${buildFilterString(
          pageFilter
        )}&fields=id,name,tags,country,user,total_links,broadcast_target,created_at,clicks,click_rate,csv,shortify_status_comment,shortify_status,file_status_comment,file_status,status_comment,status,leads,revenue,actual_links`
      );
    },

    onWaitChange() {
      if (
        this.searchName !== this.pageFilter.search &&
        (this.searchName.length >= 2 || this.searchName === "")
      ) {
        this.changeFilter({ search: this.searchName });
      }
    },

    onWaitChangeCountry() {
      if (
        this.searchCountry &&
        (this.searchCountry.length >= 2 || this.searchCountry === "")
      ) {
        this.onLoadCountries(`search=${this.searchCountry}`);
      }
    },
    onWaitChangeUser() {
      if (
        this.searchUser &&
        (this.searchUser.length >= 2 || this.searchUser === "")
      ) {
        this.onLoadUsers(`search=${this.searchUser}`);
      }
    },

    isLoadingAction(field, id) {
      return (
        this.status[field] === requestStatus.loading && this.status.id === id
      );
    },
    getTargetName(value) {
      return BROADCAST_TARGETS.find((i) => i.value === value).name;
    },
  },
  watch: {
    status(newValue) {
      this.loading = newValue.load_list === requestStatus.loading;
      this.isLoadingCountry = newValue.load_countries === requestStatus.loading;
      this.isLoadingUser = newValue.load_users === requestStatus.loading;

      if (
        newValue.change_field === "create" &&
        newValue.create === requestStatus.success
      ) {
        this.$toaster.success("Success create broadcast");
        this.onLoadData(this.pageFilter);
      }

      if (
        newValue.change_field === "retry" &&
        newValue.retry === requestStatus.success
      ) {
        this.$toaster.success("Success retry");
      }

      if (
        newValue.change_field === "create_file" &&
        newValue.create_file === requestStatus.success
      ) {
        this.$toaster.success("Success recreate file");
      }

      if (
        newValue.change_field === "fill_cache" &&
        newValue.fill_cache === requestStatus.success
      ) {
        this.$toaster.success("Success fill shortify");
      }
    },
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
    country(newValue) {
      this.changeFilter({ country: newValue ? newValue.id : null });
    },
    user(newValue) {
      this.changeFilter({ user: newValue ? newValue.id : null });
    },
    countryDetails(newValue) {
      this.country = newValue;
    },
    searchName() {
      this.onWaitChange();
    },
    searchCountry(newValue) {
      if (!this.country || newValue !== this.country.name) {
        this.onWaitChangeCountry();
      }
    },
    searchUser(newValue) {
      if (!this.user || newValue !== this.user.display_name) {
        this.onWaitChangeUser();
      }
    },
  },

  created() {
    this.searchName = this.pageFilter.search || "";
    this.onLoadData(this.pageFilter);
    this.onWaitChange = _.debounce(this.onWaitChange, 500);
    this.onWaitChangeCountry = _.debounce(this.onWaitChangeCountry, 500);
    this.onWaitChangeUser = _.debounce(this.onWaitChangeUser, 500);
    if (this.pageFilterCountry) {
      this.onLoadCountryDetails(this.pageFilterCountry);
    }
    // Load user info from filter
    if (this.pageFilterUser) {
      onLoadUserInfo(this.pageFilterUser).then((response) => {
        if (response?.response?.data)
          this.user = {
            id: response?.response?.data.id,
            display_name: response?.response?.data.username,
          };
      });
    }
  },
};
</script>

<style scoped></style>
