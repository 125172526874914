<template>
  <v-dialog
      :value="isOpen"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Details</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col >
              Name
            </v-col>
            <v-col >
              {{details.name}}
            </v-col>
          </v-row>

          <v-row>
            <v-col >
              Base Url
            </v-col>
            <v-col >
              {{details.base_url}}
            </v-col>
          </v-row>

          <v-row>
            <v-col >
              Network
            </v-col>
            <v-col >
              {{details.network}}
            </v-col>
          </v-row>

          <v-row>
            <v-col >
              Max Links
            </v-col>
            <v-col >
              {{details.max_links}}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="toggleOpenModal(null)"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {LOAD_DETAILS} from "../../../store/modules/broadcasts/types";

export default {
  name: "BroadcastDetails",
  props: {
    toggleOpenModal: Function,
    id: Number
  },
  data: () => ({
    isOpen: false
  }),
  computed: {
    ...mapState({
      details: state => state.broadcasts.details,
      status: state => state.broadcasts.status,
    })
  },
  methods: {
    ...mapActions({
      onLoadDetails: `broadcasts/${LOAD_DETAILS}`,
    })
  },
  watch: {
    id(newValue) {
      if(newValue){
        this.onLoadDetails(newValue);
      }
      this.isOpen = !!newValue;
    }
  }
}
</script>

<style scoped>

</style>