import { render, staticRenderFns } from "./EditBroadcastDialog.vue?vue&type=template&id=e2ada600&scoped=true&"
import script from "./EditBroadcastDialog.vue?vue&type=script&lang=js&"
export * from "./EditBroadcastDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2ada600",
  null
  
)

export default component.exports