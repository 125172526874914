<template>
  <v-container :fluid="true">
    <broadcasts-table :toggleOpenDetailsModal="toggleOpenDetailsModal"/>
    <broadcast-details :id="id" :toggleOpenModal="toggleOpenDetailsModal"/>
  </v-container>
</template>

<script>
import BroadcastsTable from "./components/Table";
import BroadcastDetails from "./components/BroadcastDetails";
import {mapActions, mapMutations} from "vuex";
import {ADD_HEADER_SETTINGS} from "../../store/modules/layout/types";
import {SET_DEFAULT_STATE} from "../../store/modules/broadcasts/types";

export default {
  name: "Broadcasts",
  components: {BroadcastsTable, BroadcastDetails},
  data: () => ({
    id: null
  }),
  methods: {
    ...mapActions({
      addSettings: `layout/${ADD_HEADER_SETTINGS}`,
    }),
    ...mapMutations({
      onClearData: `broadcasts/${SET_DEFAULT_STATE}`,
    }),
    toggleOpenDetailsModal(id) {
      this.id = id;
    }
  },
  beforeMount() {
    this.addSettings({
      pageName: 'Broadcasts',
      actions: []
    })
  },
  beforeRouteLeave(to, from, next) {
    this.onClearData();
    next();
  }
}
</script>

<style scoped>

</style>